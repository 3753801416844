import Foot from '../../Foot/Foot';
import Topbar from '../../Topbar/Topbar';
import './Electricidad.css';
import { Col, Container, Row } from 'react-bootstrap';
const Electricidad = () => {
    return (
        <div >
            <div className='h_main'>
                <Topbar />
            </div>
            <div className='h_main'>
            <div className='mt-5'>
                <Container>
                    <Row>
                        <Col md={10}>
                            <div className="head_left">
                                <span className='h_subTitle'>Ingeniería Eléctrica</span>
                            </div>
                            <div >
                                <p>
                                    Contamos con Ingenieros Clase-A certificados por la SEC para realizar todo el proceso de certificación de sus instalaciones.
                                    Realizamos estudios de calidad de energía, mejoramiento de factor de potencia, diseño de proyectos de energías renovables y consultorías con el fin de optimizar los costos asociados a su consumo eléctrico.
                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <div>
                <Foot/>
            </div>
        </div>


    )
}

export default Electricidad