import Topbar from '../../Topbar/Topbar';
import './ConsultoriaTI.css';
import Foot from '../../Foot/Foot';
import { Col, Container, Row } from 'react-bootstrap';
const ConsultoriaTI = () => {
    return (
        <div >
            <div className='h_main'>
            <Topbar />
            </div>
            <div className='h_main'>
            <div className='mt-5'>
                <Container>
                    <Row>
                        <Col md={10}>
                            <div className="head_left">
                                <span className='h_subTitle'>Consultoría en Tecnologías de la Información</span>
                            </div>
                            <div >
                                <h3 style={{ color: '#ceda83' }}> Servicios de Ciberseguridad </h3>
                                <p className='text-secondary'> Apoyamos en la revisión, definición, implementación y cumplimientos de polítcas de ciberseguridad. 
                                Contamos con un equipo de especialistas en ciberseguridad que apoyarán en todo lo relativo a ciberseguridad en su empresa, para cumplir con la normativa vigente.
                                </p>
                            </div>
                            <div >
                                <h3 style={{ color: '#ceda83' }}> Servicios de Jefatura de Proyectos </h3>
                                <p className='text-secondary'> Gracias a la experiencia adquirida durante estos años, contamos con un equipo de jefes de proyecto capaces de gestionar y llevar éxitosamente los proyectos de tecnología de la información.
                                </p>
                            </div>
                            <div >
                                <h3 style={{ color: '#ceda83' }}> Migración a Nube GCP </h3>
                                <p className='text-secondary'> Contamos con arquitectos certificados en Google Cloud Platform, que permitirán realizar diseños óptimos de su infraestructura en la nube.
                                </p>
                            </div>
                            <div >
                                <h3 style={{ color: '#ceda83' }}> Venta de Hardware Refurbished</h3>
                                <p className='text-secondary'> Contamos con alianzas para la obtención de productos ya descontinuados por las principales marcas, dandole una segunda vida a sus sistemas más antiguos.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <div>
                <Foot/>
            </div>
        </div>


    )
}

export default ConsultoriaTI