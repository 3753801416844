import './Header.css';
import { useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import head_rightImg from '../../assets/topo.jpg';
import head_Tech from '../../assets/tech_green.png';
import head_Terreno from '../../assets/Terreno.png';
import head_Dron from '../../assets/dron.jpg';
import head_Energias from '../../assets/energias2.jpg';
import head_Climatizacion from '../../assets/AC2.jpg';
import head_Bomba from '../../assets/bomba2.jpg';
import head_IT from '../../assets/IT2.jpg';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'; //Google Analytics

const Header = () => {
    const handleImageClick = (event, message) => {
        console.log(event.target);
        console.log(message);
        console.log('Image Clicked');
    };

    useEffect(() => {
        ReactGA.initialize('AW-11422325350');
      }, []);

    return (
        <div className='mt-5 h_main'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="head_left">
                            <span className='h_subTitle'>Servicios de Ingeniería</span>
                            <div className="w-75 mt-2 h_title">
                                <h1 style={{ color: '#ceda83' }}>Realiza Tus Proyectos</h1>
                                <h1 style={{ color: '#c1dfcd' }}>Con Nuestros</h1>
                                <h1 style={{ color: '#c1dfcd' }}>Servicios Profesionales</h1>
                            </div>
                            <p className="text-secondary"> En un mundo en qué los cambios son una constante, habilidades cómo la adaptabilidad, profesionalismo, prolijidad, tolerancia al cambio e innovación son cada vez más necesarias para posicionarse en industrias cada vez más competitivas. La globalización, es una realidad y los cambios tecnológicos son más frecuentes que antes, es un hecho que las empresas necesitan estar con proveedores de servicios que se alineen con la estrategia, transformando la relación cliente – proveedor a una de parterns de servicios. Los directores de PCF Smart, han participado en proyectos complejos de ingeniería y tienen basto conocimiento en las industrias en las que se han desarrollado profesionalmente, este conocimiento y experiencia se pone a disposición de nuestros clientes para apoyarlos en sus iniciativas para lograr sus objetivos.</p>

                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="head_right">
                            <div className="imageContainer d-flex justify-content-between aling-items-center ">
                                <img src={head_rightImg} alt="header"
                                    className='head_rightImg shadow-sm rou' />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex" >
                        <div className='head_Tech shadow-sm rou' style={{ backgroundImage: `url(${head_Tech})` }}>

                        </div>
                    </div>
                    <Col md={4}>
                        <div className="box">
                            <div className="boxContainer">
                                <div className=''>
                                    <Link to="/servicios/topografia" rel="noreferrer">
                                        <img src={head_Dron} className='img-fluid overlay' alt='' />
                                    </Link>
                                </div>
                                <div className=''>
                                    <h5 style={{ color: '#ceda83' }}> Topografía y Geomensura </h5>
                                    <p className='text-secondary' > Realizamos servicios profesionales utilizando instrumentos con precisión centimétrica para el levantamiento, replanteo y aerofotogrametría. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="box">
                            <div className="boxContainer">
                                <div className=''>
                                    <Link to="/servicios/bombas" rel="noreferrer">
                                        <img src={head_Bomba} className='img-fluid overlay' alt='' />
                                    </Link>
                                </div>
                                <div className=''>
                                    <h5 style={{ color: '#ceda83' }}> Distribución de Bombas para Lodo</h5>
                                    <p className='text-secondary' >Comercializamos soluciones para la minería y el cuidado del medio ambiente, para minería metálica y no metálica.
                                        Destacamos productos como: Hidrociclones , Tanques Clarificadores, Filtros Prensa, Bombas 100 % compatibles con bombas Warma, escurridores, Correas Transportadoras, Tanques de Agitación , entre otros.
                                        También disponemos de repuestos para bombas </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="box">
                            <div className="boxContainer">
                                <div className=''>
                                    <Link to="/servicios/electricidad" rel="noreferrer">
                                        <img src={head_Energias} className='img-fluid overlay' alt='' />
                                    </Link>
                                </div>
                                <div className=''>
                                    <h5 style={{ color: '#ceda83' }}> Consultoría Eléctrica y Energías Renovables </h5>
                                    <p className='text-secondary' >Realizamos modificaciones y certificamos de acuerdo a la normativa vigente de instalaciones eléctricas residenciales e industriales inferiores a 100 kW. También ofrecemos la capacidad de realizar proyectos de NetBilling, desde el diseño hasta la puesta en marcha. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="box">
                            <div className="boxContainer">
                                <div className=''>
                                    <Link to="/servicios/consultoriati" rel="noreferrer">
                                        <img src={head_IT} className='img-fluid overlay' alt='' />
                                    </Link>
                                </div>
                                <div className=''>
                                    <h5 style={{ color: '#ceda83' }}>Consultoría de Servicios TI</h5>
                                    <p className='text-secondary' > Contamos con consultores expertos para realizar proyectos que requieran transformar digitalmente los procesos de empresas. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Header