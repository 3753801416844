import HeaderMain from './components/HeaderMain/HeaderMain';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';


function App() {


  return (
    <div>
      <HeaderMain/>
    </div>
  );
}

export default App;
