import Topbar from '../Topbar/Topbar';
import './WhoWeAre.css';
import { Col, Container, Row } from 'react-bootstrap';
import Foot from '../Foot/Foot';

const WhoWeAre = () => {
    return (
        <div >
            <div className='h_main'>
            <Topbar />
            </div>
            <div className='h_main'>
            <div className='mt-5'>
                <Container>
                    <Row>
                        <Col md={10}>
                            <div className="head_left">
                                <span className='h_subTitle'>Quiénes Somos</span>
                            </div>
                            <div >
                                <h1 style={{ color: '#ceda83' }}>Somos una Empresa de Injenieros</h1>
                                <h2 style={{ color: '#c1dfcd' }}>Visión</h2>
                                <p className='text-secondary'>
                                Convertirnos en líderes de servicios en las distintas áreas de negocio de nuestra compañía, 
                                brindando servicios profesionales e innovando en soluciones tecnológicas que provean solución
                                 a las cambiantes necesidades de la industria, siendo flexibles e incorporando rápidamente los 
                                 conocimientos tecnológicos, adaptándolos a necesidades reales.
                                </p>
                                <h2 style={{ color: '#c1dfcd' }}>Misión</h2>
                                <p className='text-secondary'>
                                Aplicar la experiencia obtenida en grandes proyectos de ingeniería, 
                                aplicándola a la solución de necesidades cotidianas en industrias de menor tamaño 
                                siguiendo los altos estándares y normativas vigentes para el éxito en cada uno de nuestros proyectos.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <div>
                <Foot />
            </div>
        </div>


    )
}

export default WhoWeAre