import Topbar from '../../Topbar/Topbar';
import Foot from '../../Foot/Foot';
import './Climatizacion.css';
import { Col, Container, Row } from 'react-bootstrap';
const Climatizacion = () => {
    return (
        <div >
            <div className='h_main'>
            <Topbar />
            </div>
            <div className='h_main'>
            <div className='mt-5'>
                <Container>
                    <Row>
                        <Col md={10}>
                            <div className="head_left">
                                <span className='h_subTitle'>Climatización</span>
                            </div>
                            <div >
                                <h1 style={{ color: '#ceda83' }}>Somos una Empresa de Ingenieros para Ingenieros </h1>
                                <h2 style={{ color: '#c1dfcd' }}>Visión</h2>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                             sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae 
                             auctor eu. Mauris pellentesque pulvinar pellentesque habitant morbi tristique. 
                             Sed turpis tincidunt id aliquet risus feugiat in ante metus. Scelerisque fermentum 
                             dui faucibus in ornare quam viverra orci sagittis. Pretium lectus quam id leo in vitae 
                             turpis massa sed. Volutpat est velit egestas dui id ornare arcu. Nulla facilisi morbi 
                             tempus iaculis urna id volutpat. Lorem ipsum dolor sit amet consectetur adipiscing. 
                             Ornare lectus sit amet est placerat in egestas. Velit dignissim sodales ut eu sem 
                             integer vitae justo eget. In pellentesque massa placerat duis ultricies lacus sed turpis tincidunt.
                                </p>
                                <h2 style={{ color: '#c1dfcd' }}>Misión</h2>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                             sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae 
                             auctor eu. Mauris pellentesque pulvinar pellentesque habitant morbi tristique. 
                             Sed turpis tincidunt id aliquet risus feugiat in ante metus. Scelerisque fermentum 
                             dui faucibus in ornare quam viverra orci sagittis. Pretium lectus quam id leo in vitae 
                             turpis massa sed. Volutpat est velit egestas dui id ornare arcu. Nulla facilisi morbi 
                             tempus iaculis urna id volutpat. Lorem ipsum dolor sit amet consectetur adipiscing. 
                             Ornare lectus sit amet est placerat in egestas. Velit dignissim sodales ut eu sem 
                             integer vitae justo eget. In pellentesque massa placerat duis ultricies lacus sed turpis tincidunt.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <div>
                <Foot/>
            </div>
        </div>


    )
}

export default Climatizacion