import Foot from '../../Foot/Foot';
import Topbar from '../../Topbar/Topbar';
import './Topografia.css';
import { Col, Container, Row } from 'react-bootstrap';
const Topografía = () => {
    return (
        <div >
            <div className='h_main'>
                <Topbar />
            </div>
            <div className='h_main'>
                <div className='mt-5'>
                    <Container>
                        <Row>
                            <Col md={10}>
                                <div className="head_left">
                                    <span className='h_subTitle'>Topografía</span>
                                </div>
                                <div >
                                    <h4 style={{ color: '#ceda83' }}>Levantamiento Topográfico</h4>
                                    <p className='text-secondary'>
                                    Realizamos en todo el territorio nacional el levantamiento de puntos de interés y superficies utilizando tecnología GNSS y estaciones totales de topografía. Los puntos levantados son enviados a AutoCAD Civil para modelar superficies, secciones, geometrías de cursos de agua y ríos, y proyección obras civiles.
                                    </p>
                                    <h4 style={{ color: '#ceda83' }}>Aerofotogrametría</h4>
                                    <p className='text-secondary'>
                                        Consiste en un levantamiento topográfico mediante el uso de dron, se entrega una ortofoto con la imagen del sector levantado en formato TIFF. Está ortofoto, permite visualizar con un alto nivel de detalle y precisión el área de interés.
                                    </p>
                                    <h4 style={{ color: '#ceda83' }}>Replanteo de Obras Civiles y Monumentación de Hitos</h4>
                                    <p className='text-secondary'>
                                        Este servicio consiste en la materialización en terreno de la ubicación de los puntos de interés del proyecto, cubicación de materiales para la realización y control de avance de las obras proyectadas.
                                    </p>
                                    <h4 style={{ color: '#ceda83' }}>Loteo y Subdivisión</h4>
                                    <p className='text-secondary'>
                                        Tenemos la capacidad de efectuar el levantamiento topográfico de la propiedad para después realizar una propuesta de subdivisión de acuerdo a los requerimientos del cliente y normativa vigente. Una vez realizada y acordada la propuesta se despliegan en terreno los vértices de las propiedades resultantes.</p>
                                    <p>Contamos con un servicio de verificación de deslindes para asegurar que lo descrito en la escritura cumpla con lo existente en terreno.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <Foot />
            </div>
        </div>


    )
}

export default Topografía