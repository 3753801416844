import Topbar from '../../Topbar/Topbar';
import Foot from '../../Foot/Foot';
import './DistribucionAoji.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useState, useEffect } from 'react';
import head_Dron from '../../../assets/dron.jpg';

const baseURL = 'http://localhost:3001/aojin/';

const DistribucionAoji = () => {
    const [products, setProducts] = useState(null);

    useEffect(() => {
        axios.get(baseURL).then((response) => {
            setProducts(response.data.rows);
            //console.log(products);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    //console.log(products);
    if (products) {
        return (
            <div>
                <div className='h_main'>
                    <Topbar />
                </div>
                <div className='h_main'>
                    <div className='mt-5'>
                        <Container>
                            <Row>
                                <Col md={10}>
                                    <div className="head_left">
                                        <span className='h_subTitle'>Distribución de Bombas y Repuestos de Bombas Centrifugas para Lodos</span>
                                    </div>
                                    <div >
                                        <h3 style={{ color: '#ceda83' }}>Comercializamos soluciones para la minería y el cuidado del medio ambiente, para minería metálica y no metálica. </h3>
                                        <p className='text-secondary'>Destacamos productos como: Hidrociclones , Tanques Clarificadores, Filtros Prensa, Bombas 100 % compatibles con bombas Warma, escurridores, Correas Transportadoras, Tanques de Agitación , entre otros.
                                            <p className='text-secondary'>También disponemos de repuestos para bombas.</p>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='mt-5'>
                        <Container>
                            <h1 style={{ color: '#ceda83' }}> Productos Aojin </h1>
                        </Container>
                        <Container className="d-flex justify-content-between aling-items-center">
                            <Row>
                                {Object.keys(products).map((ID) => (
                                    <Col md={4} key={ID} >
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img src={head_Dron} />
                                            <Card.Body>
                                                <Card.Title>
                                                    <span>{products[ID].PART_NUMBER}</span>
                                                </Card.Title>
                                                <Card.Text>
                                                    <span>{products[ID].DESCRIPTION}</span>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>
                </div>
                <div>
                    <Foot />
                </div>
            </div>
        );
    }
    else
        return (
            <div >
                <div className='h_main'>
                    <Topbar />
                </div>
                <div className='h_main'>
                    <div className='mt-5'>
                        <Container>
                            <Row>
                                <Col md={10}>
                                    <div className="head_left">
                                        <span className='h_subTitle'>Distribucion de Bombas para Lodos</span>
                                    </div>
                                    <div >
                                        <h1 style={{ color: '#ceda83' }}> </h1>
                                        <p className='text-secondary'>Comercializamos soluciones para la minería y el cuidado del medio ambiente, para minería metálica y no metálica. </p>
                                        <p className='text-secondary'>Destacamos productos como: Hidrociclones , Tanques Clarificadores, Filtros Prensa, Bombas 100 % compatibles con bombas Warman, escurridores, Correas Transportadoras, Tanques de Agitación , entre otros.</p>
                                        <p className='text-secondary'>También disponemos de repuestos para bombas.</p>

                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
                <div>
                    <Foot />
                </div>
            </div>


        )
}

export default DistribucionAoji