import './Topbar.css';
import Container from 'react-bootstrap/Container';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';

import logo from '../../assets/logo.png';
import { Button } from 'react-bootstrap';

const Topbar = () => {
    return (
        <div className="h_main">
            <Navbar expand="lg" className="bg-transparent shadow-sm">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="logo" style={{ width: "8rem", height: "100%", objectFit: 'cover' }}></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto text-center d-flex justify-content-center allign-items-center">
                            <NavDropdown title="Productos y Servicios" id="basic-nav-dropdown" className="text-secondary fw-bold">
                                <NavDropdown.Item href="/servicios/bombas" className="text-secondary fw-bold">Repuestos para Bombas Warman</NavDropdown.Item>
                                <NavDropdown.Item href="/servicios/topografia" className="text-secondary fw-bold">Topografía</NavDropdown.Item>
                                <NavDropdown.Item href="/servicios/electricidad" className="text-secondary fw-bold">Consultoría Eléctrica y Energías Renovables</NavDropdown.Item>
                                {/* <NavDropdown.Item href="/servicios/climatizacion" className="text-secondary fw-bold">Climatización</NavDropdown.Item> */}
                                <NavDropdown.Item href="/servicios/consultoriati" className="text-secondary fw-bold">Consultoría TI</NavDropdown.Item>
                                {/*<NavDropdown.Divider />*/}
                            </NavDropdown>
                            <Nav.Link href="/whoweare" className="text-secondary fw-bold">Quiénes somos</Nav.Link>
                            {/* <Nav.Link href="/contact" className="text-secondary fw-bold">Contacto</Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Topbar