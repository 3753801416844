import Header from '../Header/Header';
import Topbar from '../Topbar/Topbar';
import './HeaderMain.css';
import Foot from '../Foot/Foot';

const HeaderMain = () => {
    return (
        <div className="h_main">
            <Topbar/>
            <Header/>
            <Foot/>
        </div>
    )
}

export default HeaderMain