import Topbar from '../Topbar/Topbar';
import { Button, Form, Col, Container, Row } from 'react-bootstrap';
import './Contact.css';
import React from 'react';
import { useState } from 'react';
import Foot from '../Foot/Foot';

const Contact = () => {
    const [phonenumber, setPhonenumber] = useState("");
    const checkPhoneNumber = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setPhonenumber(onlyDigits);
    };
    return (
        <div >
            <div className="h_main ">
                <Topbar />
            </div>
            <div className='h_main'>
                <Container >
                    <div className='mt-5'>
                        <Form>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control type="text" placeholder="Ingrese su nombre completo" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Correo Electrónico</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPhoneNumber">
                                <Form.Label>Número de Teléfono</Form.Label>
                                <Form.Control type="tel" placeholder="Ingrese su número de teléfono"
                                    value={phonenumber}
                                    onChange={(e) => checkPhoneNumber(e)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formConsulta">
                                <Form.Label>Ingresa tu Consulta</Form.Label>
                                <Form.Control as="textarea" placeholder="Consulta" rows={4} />
                            </Form.Group>
                            <Button className='contact_btn fw-bold text-decoration-none' variant="primary" type="submit">
                                Contactanos
                            </Button>
                        </Form>

                    </div>
                </Container>
            </div>
            <div>
                <Foot />
            </div>
        </div>
    )
}

export default Contact