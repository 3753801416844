import './Foot.css'
import { Col, Container, Row } from 'react-bootstrap';

const Foot = () => {
    return (
        <div>
            <footer className='footer-section f_main'>
                <Container >
                    <div className="footer-cta pt-5 pb-5">
                        <Row className='mt-5 justify-content-center'>
                            <Col md={2} mb={30}>
                                <div className='single-cta'>
                                    <div className='fas fa-map-marker-alt'>
                                        <div className='cta-text'>
                                            <h4 style={{ color: '#ceda83' }}> Dirección </h4>
                                            <span> Miraflores 383, piso 4, oficina 401, Santiago </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/*
                            <Col md={2} mb={30}>
                                <div className='single-cta'>
                                    <div className='fas fa-map-marker-alt'>
                                        <div className='cta-text'>
                                            <h4 style={{ color: '#ceda83' }}> Teléfono </h4>
                                            <span> +56 9 8299 1803 </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            */}
                            <Col md={2} mb={30}>
                                <div className='single-cta'>
                                    <div className='fas fa-map-marker-alt'>
                                        <div className='cta-text'>
                                            <h4 style={{ color: '#ceda83' }}> Email </h4>
                                            <span> ventas@pcfsmart.cl </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </footer>
        </div>

    )
}

export default Foot;