import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from './components/Contact/Contact';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Servicios from './components/Services/Servicios';
import Topografía from './components/Services/Topografia/Topografia';
import Electricidad from './components/Services/Electricidad/Electricidad';
import Climatizacion from './components/Services/Climatizacion/Climatizacion';
import DistribucionAoji from './components/Services/DistribucionAoji/DistribucionAoji';
import ConsultoriaTI from './components/Services/ConsultoriaTI/ConsultoriaTI';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route exact path='/whoweare' element={<WhoWeAre />} />
        <Route exact path='/contact' element={<Contact />} />
          <Route path='/servicios/topografia' element={<Topografía />}/>
          <Route path='/servicios/electricidad' element={<Electricidad />}/>
          <Route path='/servicios/climatizacion' element={<Climatizacion />}/>
          <Route path='/servicios/consultoriati' element={<ConsultoriaTI />}/>
          <Route path='/servicios/bombas' element={<DistribucionAoji/>}/>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
